<template>
    <div class="p-fluid">
        <div class="formgrid grid">
            <div class="field col-12 md:col-6 lg:col-3">
                <label>Tipe Outlet</label>
                <MultiSelect id="otype_id" dataKey="otype_id" v-model="otype_ids" :loading="loadingDropdownOutletType"
                    :options="dataDropdownOutletType"
                    optionLabel="label" placeholder="Pilih Tipe Outlet" :filter="true" :showToggleAll="false"
                    :showClear="true" @filter="searchDropdownOutletType($event, 'filter')"/>
            </div>
        </div>
    </div>
    <Button label="Clear Filter" icon="pi pi-times" class="p-button-warning mr-2 my-1" @click="clearFilter"/>
    <div class="flex flex-column md:flex-row md:justify-content-between md:align-items-center mb-2">
        <div>
            <Button type="button" :label="'Total '+this.total_marker" icon="pi pi-map" class="p-button-primary"/>
        </div>
        <span class="block mt-2 md:mt-0 p-input-icon-left">
            <Button icon="pi pi-refresh" :loading="loading" class="p-button-warning" @click="showMarker()"/>
        </span>
    </div>
        <BlockUI :blocked="loading">
        <div class="map" id="map-mti"></div>
    </BlockUI>
</template>

<script>

// eslint-disable-next-line no-undef
var markers=  L.markerClusterGroup();

export default {
    props: [
		"filter",
		"filter2",
    ],
    data() {
        return {
            // loading
            loadingDropdownOutletType: false,

            // dataDropdown
            dataDropdownOutletType: null,

            // filter
            filters: {
                otype_id: null,
            },
            otype_ids: null,

            // maps
            loading: false,
            map: null,
            zoom: 5,
            lat: -3.375126556326247,
            lng: 118.84902874210889,
            location_list: [],
            total_marker: 0,

            outletTypeColors: {},  // Menyimpan warna berdasarkan tipe outlet
            legendControl: null,  // Simpan referensi ke kontrol legenda
            
        }
    },
    mounted() {
        this.searchDropdownOutletType('');

        // eslint-disable-next-line no-undef
        this.map = L.map('map-mti', {
            attributionControl: false,
            doubleClickZoom: true,
            zoomControl: true,
            zoom:1,
            zoomAnimation:false,
            fadeAnimation:true,
            markerZoomAnimation:true
        }).setView([this.lat, this.lng], this.zoom);

        // eslint-disable-next-line no-undef
        L.tileLayer('https://{s}.tile.osm.org/{z}/{x}/{y}.png', {
            attribution: '© OpenStreetMap'
        }).addTo(this.map);

    },
    created(){
        this.showMarker();
    },
    watch: {
        filters: {
            handler() {
                setTimeout(() => {
                    this.showMarker() // Panggil metode yang ingin dijalankan
                }, 500);
            },
            deep: true
        },
        filter: {
            handler() {
                setTimeout(() => {
                    this.showMarker() // Panggil metode yang ingin dijalankan
                }, 500);
            },
            deep: true
        },
        filter2: {
            handler() {
                setTimeout(() => {
                    this.showMarker() // Panggil metode yang ingin dijalankan
                }, 500);
            },
            deep: true
        },
        otype_ids() {
            this.filters.otype_id = this.otype_ids ? this.convertMultiSelectOutletType(this.otype_ids) : null;
        },
    },
    methods:{
        convertMultiSelectOutletType(data) {
            if(data){
                return data.map((item) => {
                    return item.otype_id;
                });
            }
        },
        clearFilter(){
            this.filters.otype_id = null;
            this.otype_ids = null;
        },
        //DROPDOWN
        searchDropdownOutletType(event, purpose, valueEdit) {
            setTimeout(() => {
                if (valueEdit) {
                    this.$refs.doutlettype.filterValue = valueEdit;
                }

                if (purpose == "filter") {
                    this.loadingDropdownOutletType = true;
                }

                this.axios({
                    method: 'GET',
                    url: process.env.VUE_APP_ROOT_API + 'web/select2/outlet-type-maps-mti',
                    params: {
                        "search": valueEdit ? valueEdit : event.value,
                    }
                })
                    .then(res => {

                        if (purpose == "filter") {
                            this.dataDropdownOutletType = res.data.data;
                            this.loadingDropdownOutletType = false;

                            //Multiple Selected
                            if(this.otype_ids){
                                this.dataDropdownOutletType.push(...this.otype_ids);

                                const uniqueids= [];
                                this.dataDropdownOutletType = this.dataDropdownOutletType.filter((c) => {
                                    const isDup = uniqueids.includes(c.otype_id);
                                    if(!isDup){
                                        uniqueids.push(c.otype_id);
                                        return true;
                                    } 
                                    return false;
                                });
                            }

                        } else if (purpose == null) {
                            this.dataDropdownOutletType = res.data.data;
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            }, 250);
        },
        //MAPS
        showMarker(){

            this.loading= true;

            markers.clearLayers();
            
            this.axios({
                method: 'GET',
                url: process.env.VUE_APP_ROOT_API + 'web/dashboard/maps-mti',
                params: {
                    "userweb_id": this.filter.userweb_id,
                    "periode": this.filter2.period,
                    "otype_id": this.filters.otype_id,
                }
            })
            .then(res => {
                this.location_list = res.data.data;
                this.total_marker = res.data.data.length;

                // Reset outletTypeColors untuk memulai dari awal
                this.outletTypeColors = {};

                // eslint-disable-next-line no-unused-vars
                this.location_list.map(function(value, key) {
                    if(value.outlet_latitude != null && value.outlet_longitude != null){

                        var htmlcontent = "<b>Outlet Name</b> : " + value.outlet_name + "</b><br/>";
                            htmlcontent += "<b>Outlet Type</b> : " + value.outlet_type + "<br/>";
                            htmlcontent += "<b>Total SKU</b> : " + value.total_sku + "<br/>";
                            htmlcontent += "<b>Last Visit</b> : " + value.last_visit + "<br/>";

                    // Dapatkan warna berdasarkan tipe outlet
                    const markerColor = value.hexcolor;
                    
                     // Simpan warna berdasarkan tipe outlet
                    if (!this.outletTypeColors[value.outlet_type]) {
                        this.outletTypeColors[value.outlet_type] = markerColor;
                    }

                    // Gunakan marker dengan warna
                    // eslint-disable-next-line no-undef
                    var marker = L.circleMarker([value.outlet_latitude, value.outlet_longitude], {
                        color: markerColor, // Set warna marker sesuai tipe outlet
                        radius: 8, // Ukuran lingkaran
                        fillColor: markerColor,
                        fillOpacity: 0.8
                    });

                        // eslint-disable-next-line no-unused-vars
                        var popup = marker.bindPopup(htmlcontent);
                        markers.addLayer(marker);
                    }
                },this); 
                
                this.map.addLayer(markers);

                this.showLegend();

                this.loading= false;
            })
            .catch((err) => {
                this.loading= false;
                console.log(err);
            });
        },
        removeExistingLegend() {
            if (this.legendControl) {
                this.map.removeControl(this.legendControl);
                this.legendControl = null;
            }
        },
        showLegend() {
            setTimeout(() => {
                this.removeExistingLegend();  // Hapus legenda yang ada

                // eslint-disable-next-line no-undef
                this.legendControl = L.control({ position: "bottomleft" });

                this.legendControl.onAdd = () => {

                    // eslint-disable-next-line no-undef
                    var div = L.DomUtil.create("div", "legend");
                    div.innerHTML += "<h4>Keterangan</h4>";

                    if (Object.keys(this.outletTypeColors).length === 0) {
                        div.innerHTML += "<p>Tidak ada tipe outlet yang tersedia.</p>";
                        return div;
                    }

                    Object.keys(this.outletTypeColors).forEach(type => {
                        const color = this.outletTypeColors[type];
                        div.innerHTML += `<i style="background: ${color}; width: 18px; height: 18px; display: inline-block; margin-right: 8px;"></i><span>${type}</span><br>`;
                    });

                    return div;
                };

                this.legendControl.addTo(this.map);  // Tambah legenda baru ke peta
            }, 250);
        },
    }
}
</script>

<style>
	.map {
		height: 710px;
        width: 100%;
	}
    /*Legend specific*/
    .legend {
        padding: 6px 8px;
        font: 14px Arial, Helvetica, sans-serif;
        background: white;
        background: rgba(255, 255, 255, 0.8);
        /*box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);*/
        /*border-radius: 5px;*/
        line-height: 24px;
        color: #555;
    }

    .legend h4 {
        text-align: center;
        font-size: 16px;
        margin: 2px 12px 8px;
        color: #777;
    }

    .legend span {
        position: relative;
        bottom: 3px;
    }

    .legend i {
        width: 18px;
        height: 18px;
        float: left;
        margin: 0 8px 0 0;
        opacity: 0.7;
    }

    .legend i.icon {
        background-size: 18px;
        background-color: rgba(255, 255, 255, 1);
    }
</style>
