<template>
    <BlockUI :blocked="loadingCard">
        <div class="surface-card shadow-2 p-3 border-round">
            <div class="flex justify-content-between mb-3">
                <div>
                    <span class="block font-bold text-2xl mb-3 underline">NON EC</span>
                    <div class="text-500 font-medium text-xl">
                        {{result}}
                    </div>
                </div>
                <div class="flex align-items-center justify-content-center bg-blue-100 border-round" style="width:2.5rem;height:2.5rem">
                    <i class="pi pi-user text-blue-500 text-xl"></i>
                </div>
            </div>
        </div>
    </BlockUI>
</template>

<script>

import moment from "moment";

export default {
    props: [
		"filter",
		"filter2",
    ],
    data() {
        return {
            loading: false,
            loadingCard: false,

            item: null,
            result: null,
        }
    },
    mounted() {
        this.getData();
        this.$store.commit('setErrors', {});
    },
    created(){

    },
    watch: {
        filter: {
            handler() {
                setTimeout(() => {
                    this.getData();
                }, 500);
            },
            deep: true
        },
        filter2: {
            handler() {
                setTimeout(() => {
                    this.getData();
                }, 500);
            },
            deep: true
        },
    },
    computed:{
    },
    methods:{
        formatDate(value) {
            if (value) {
                return moment.utc(value).format("DD-MM-YYYY");
            }
        },
        getData(){

            this.loadingCard=true;

            this.axios({
                method: 'GET',
                url: process.env.VUE_APP_ROOT_API + 'web/dashboard/non-ec-mti-count',
                params: {
                    "userweb_id": this.filter.userweb_id,
                    "periode": this.filter2.period,
                }
            })
            .then(res => {
                this.item = res.data.data;
                this.result = this.item.ec_count;

                this.loadingCard= false;
            })
            .catch((err) => {
                this.loadingCard= false;
                console.log(err);
            });
        },
    }
}
</script>

<style>
.user_active{
    transition: all 0.3s; /* untuk memberikan efek transisi saat hover */
}
.user_active:hover {
    box-shadow: 4px 4px 12px rgba(0, 0, 0, 0.2); /* meningkatkan efek shadow */
    transform: scale(1.01); /* mengubah skala card */
    cursor: pointer; /* mengubah cursor menjadi bentuk pointer saat hover */
}
</style>
