<template>
    <BlockUI :blocked="loadingCard">
        <div class="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
            <div>
                <Dropdown v-model="filters.tipe" :showClear="true" :options="dataDropdownTipe" optionLabel="name" optionValue="code" placeholder="Pilih Tipe" />    
            </div>
            <Badge value="Export Excel" size="large" severity="success" @click="exportExcelCSV('xlsx')" class="detail_active" ></Badge>
        </div>
        <Chart type="line" :data="dataChart" :options="options"/>
    </BlockUI>
</template>

<script>

export default {
    props: [
		"filter",
		"filter2",
		// "startDate",
		// "endDate",
    ],
    data() {
        return {
            // loading
            loading: false,
            loadingCard: false,
            loadingExcel: false,
            loadingCsv: false,

            // dataDropdown
            dataDropdownTipe: [
                {name: 'Listing', code: 'Listing'},
                {name: 'NPL', code: 'NPL'},
                {name: 'OOS', code: 'OOS'},
            ],

            // filter
            filters: {
                tipe: null,
            },

            percentage: null,
            dataChart: null,
            options: {
                responsive: true,
                plugins: {
                    legend: {
                        position: 'bottom',
                        display: false,
                    },
                    title: {
                        display: true, // Menampilkan judul
                        text: 'AVG SKU PER OUTLET', // Teks judul chart
                        font: {
                            size: 18, // Ukuran font judul
                            weight: 'bold', // Berat font judul
                        },
                        padding: {
                            top: 10, // Padding atas
                            bottom: 30 // Padding bawah
                        }
                    },
                },
            },
        }
    },
	mounted() {
        this.getData();
	},
    computed:{
    },
    watch: {
        filters: {
            handler() {
                setTimeout(() => {
                    this.getData()
                }, 500);
            },
            deep: true
        },
        filter: {
            handler() {
                setTimeout(() => {
                    this.getData();
                }, 500);
            },
            deep: true
        },
        filter2: {
            handler() {
                setTimeout(() => {
                    this.getData();
                }, 500);
            },
            deep: true
        },
        // endDate: {
        //     handler() {
        //         setTimeout(() => {
        //             if(
        //                 this.endDate !== null && 
        //                 this.endDate !== 'Invalid date'
        //             ){  
        //                 this.getData();
        //             }
        //         }, 500);
        //     },
        // }
    },
	methods: {
        getData(){

            this.loadingCard= true;

            this.axios({
                method: 'GET',
                url: process.env.VUE_APP_ROOT_API + 'web/dashboard/avg-sku-outlet-chart',
                params: {
                    "userweb_id": this.filter.userweb_id,
                    "periode": this.filter2.period,
                    "tipe": this.filters.tipe,
                    // "tanggal_mulai": this.startDate,
                    // "tanggal_akhir": this.endDate,
                }
            })
            .then(res => {
                this.dataChart = res.data.data;
                
                if(this.filters.tipe){
                    this.options.plugins.title.text = "AVG SKU PER OUTLET (" + this.filters.tipe + ")";
                }else{
                    this.options.plugins.title.text = "AVG SKU PER OUTLET";
                }

                this.percentage =  res.data.data.datasets[0].persen;
                this.loadingCard= false;
                
            })
            .catch((err) => {
                this.loadingCard= false;
                console.log(err);
            });
        },
        // EXPORT
        exportExcelCSV(ext){
            if(ext == 'xlsx'){
                this.loadingExcel=true;
            }else if(ext == 'csv'){
                this.loadingCsv=true;
            }

            this.axios({
                method: 'GET',
                url: process.env.VUE_APP_ROOT_API + 'web/dashboard/avg-sku-outlet-chart/export',
                responseType: 'blob',
                params: {
                    "ext" : ext,
                    "userweb_id": this.filter.userweb_id,
                    "periode": this.filter2.period,
                }
            })
            .then(response => {
                let fileUrl = window.URL.createObjectURL(response.data);
                let fileLink = document.createElement('a');

                fileLink.href = fileUrl;

                fileLink.setAttribute('download', 'Avg SKU Outlet Export.' + ext);
                
                document.body.appendChild(fileLink)

                fileLink.click();
                
                if(ext == 'xlsx'){
                    this.loadingExcel=false;
                }else if(ext == 'csv'){
                    this.loadingCsv=false;
                }

            })
            .catch((err) => {
                console.log(err);
                
                if(ext == 'xlsx'){
                    this.loadingExcel=false;
                }else if(ext == 'csv'){
                    this.loadingCsv=false;
                }
            });
        },
	},
}
</script>

<style>
#chartjs-tooltip{
    background: #ddd;
    padding: 8px
}
.external-tooltip {
  position: absolute;
  font-family: Arial, sans-serif;
  font-size: 12px;
  z-index: 9999;
  pointer-events: none;
  opacity: 0;
  transition: opacity 0.3s;
}

.external-tooltip table {
  width: 100%;
  margin: 0;
  padding: 0;
  border-collapse: collapse;
}

.external-tooltip th,
.external-tooltip td {
  padding: 6px 8px;
  text-align: left;
}

.external-tooltip th {
  background-color: #333;
  color: #fff;
  font-weight: bold;
}

.external-tooltip td {
  background-color: #fff;
  border-top: 1px solid #ddd;
}

.external-tooltip td span {
  display: inline-block;
  width: 12px;
  height: 12px;
  margin-right: 6px;
  vertical-align: middle;
}

.external-tooltip .tooltip-title {
  font-weight: bold;
  margin-bottom: 6px;
}

.external-tooltip .tooltip-body {
  margin-top: 6px;
}


.detail_active{
    transition: all 0.3s; /* untuk memberikan efek transisi saat hover */
}
.detail_active:hover {
    box-shadow: 4px 4px 12px rgba(0, 0, 0, 0.2); /* meningkatkan efek shadow */
    transform: scale(1.01); /* mengubah skala card */
    cursor: pointer; /* mengubah cursor menjadi bentuk pointer saat hover */
}
</style>